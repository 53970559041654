import styled from 'styled-components'

const StyledContainer = styled.div`
  text-align: center;
  max-width: 320px;
`

const Subtitle = styled.p`
  font-size: 1.6rem;
  font-family: 'Proxima Nova';
  font-weight: 400;
  color: ${props => props.theme.colors.grey300};
`

export { StyledContainer, Subtitle }

import styled from 'styled-components'

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
`

const Header = styled.h2`
  font-size: 3rem;
  font-family: 'Proxima Nova';
  font-weight: 700;
  line-height: 36px;

  color: ${props => props.theme.colors.darkGrey};
`

const InnerContainer = styled.div`
  margin-top: 100px;
  ${props => props.theme.mq.small.css`
	margin-top: 20px;
`}
`

export { Layout, Header, InnerContainer }

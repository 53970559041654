import React, { useState, useEffect } from 'react'
import Logo from 'components/Icons/Logo'
import PropTypes from 'prop-types'
import {
  Layout,
  Form,
  SuccessPage,
  InnerContainer,
} from 'components/ResetPassword'
import Page from 'components/Page'
import { navigate } from 'gatsby'

const ResetPasswordTemplate = ({ pageContext: lang }) => {
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = () => {
    setIsSuccess(true)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const user = params.get('u')
    const token = params.get('t')

    if (!user || !token) {
      navigate('/404')
    }
  }, [])

  const Content = isSuccess ? (
    <SuccessPage />
  ) : (
    <Form onSuccess={handleSubmit} />
  )

  return (
    <Page title="Reset Password" translates={lang.lang}>
      <Layout>
        <Logo />
        <InnerContainer>{Content}</InnerContainer>
      </Layout>
    </Page>
  )
}
ResetPasswordTemplate.propTypes = {
  pageContext: PropTypes.shape({
    faq: PropTypes.any,
    lang: PropTypes.any,
  }).isRequired,
}

export default ResetPasswordTemplate

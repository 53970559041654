import styled from 'styled-components'

const StyledForm = styled.form`
  font-size: 1.3rem;
  font-weight: 400;
`

const StyledInputContainer = styled.div`
  display: flex;
  margin: 6px 0px;
  border-radius: 8px;
  border: ${props => `1px solid  ${props.theme.colors.grey200}`};
  height: 42px;
  width: 335px;
  padding: 0 12px;
  font-size: 1.5rem;
`
const StyledInput = styled.input`
  height: 100%;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const StyledButton = styled.button`
  background: linear-gradient(88.72deg, #9f2ffe 0%, #cd5cff 100%);
  border: none;
  height: 56px;
  width: 335px;
  border-radius: 32px;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 26px;
  color: #ffffff;

  &:disabled {
    background: linear-gradient(88.72deg, #cd95fd 0%, #e8b4ff 100%);
  }
`

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.grey100};
  text-transform: uppercase;
  font-weight: 600;
`

const StyledError = styled.div`
  color: ${props => props.theme.colors.error};
`

const StyledValidationContainer = styled.div`
  margin-bottom: 22px;
  display: flex;
`
const StyledValidationLabel = styled.div`
  color: ${props => props.theme.colors.grey300};
  margin-left: 6px;
`

export {
  StyledForm,
  StyledInput,
  StyledButton,
  StyledLabel,
  StyledError,
  StyledValidationLabel,
  StyledInputContainer,
  StyledValidationContainer,
}

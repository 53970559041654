import React from 'react'
import Lock from '../../Icons/LockIcon'
import { Header } from '../styled'
import { StyledContainer, Subtitle } from './styled'

export const SuccessPage = () => (
  <StyledContainer>
    <Lock />
    <Header>Your password has been changed</Header>
    <Subtitle>You may now log in using your new password.</Subtitle>
  </StyledContainer>
)

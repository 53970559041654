import styled, { css, keyframes } from 'styled-components'

const fadein = keyframes`
    0% {
     top: 0; opacity: 0;
     }
     100% {
       top: 30px; opacity: 1;
     }
 `
const fadeout = keyframes`
    0% {
     top: 30px; opacity: 1;
     }
     100% {
       top: 0; opacity: 0;
     }
 `

const showStyles = css`
  visibility: visible;
  animation: ${fadein} 0.5s, ${fadeout} 0.5s 2.5s;
`

export const Container = styled.div`
  visibility: hidden;
  max-width: 345px;
  background: #fff1f3;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
  align-items: center;
  display: flex;
  height: 72px;
  padding: 15px;
  position: fixed;
  border-radius: 12px;
  z-index: 100;
  top: 30px;
  ${({ show }) => show && showStyles}

  p {
    color: #ff607f;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 21px;
  }

  svg {
    width: 40px;
    align-self: flex-start;
    /* background-color: blue; */
  }
`
export const Toast = styled.div``

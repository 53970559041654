import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { formSchema } from './schema'

export const useResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    getFieldState,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  })

  return {
    handleSubmit,
    register,
    errors,
    reset,
    watch,
    getValues,
    getFieldState,
  }
}

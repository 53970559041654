import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styled'
import CircleWarning from '../../Icons/CircleWarning'

export const TOAST_TIMEOUT = 3000

// TODO: This could be re-worked to be more generic
export const Toast = ({ show = false }) => (
  <Container show={show}>
    <CircleWarning />
    <p>Failed to reset password due to error. Please try again.</p>
  </Container>
)

Toast.propTypes = {
  show: PropTypes.bool.isRequired,
}

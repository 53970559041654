import * as React from 'react'

const CircleWarning = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-6a.75.75 0 0 1-.75-.75v-5.5a.75.75 0 0 1 1.5 0v5.5A.75.75 0 0 1 10 12Zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      fill="#FF607F"
    />
  </svg>
)

export default CircleWarning

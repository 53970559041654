import * as Yup from 'yup'

export const MIN_PASSWORD_LENGTH = 6
export const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(
      MIN_PASSWORD_LENGTH,
      `Password must contain at least ${MIN_PASSWORD_LENGTH} characters`
    ),
  cpassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords should match'),
})

import * as React from 'react'
import PropTypes from 'prop-types'

const Check = ({ checked, ...restProps }) => (
  <svg
    width={14}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M13.5 7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
      stroke={checked ? '#1DD93D' : '#A09AA5'}
      fill={checked ? '#1DD93D' : 'transparent'}
    />
    <path
      d="M9.065 6.1 6.23 8.993 4.713 7.466"
      stroke={checked ? 'white' : '#A09AA5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

Check.propTypes = {
  checked: PropTypes.bool.isRequired,
}

export default Check

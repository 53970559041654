import React from 'react'
import { Link } from 'gatsby'
// import variables from '../../styles/variables'

// const styles = {
//   position: 'relative',
//   zIndex: variables.HeaderZIndex,
// }

const Logo = props => (
  <Link to="/">
    <svg
      width={47}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.23 0c-.964 0-1.97.337-2.914.522-1.03.201-2.063.39-3.099.566-2.812.477-5.64.867-8.482 1.117a59.41 59.41 0 0 1-1.574.124c-.356.022-.81-.026-1.105.215-.118.097-.79 1.759-.514 2.392.164.473.515.822 1.283.822 4.823 0 14.9-2.527 14.9-2.527S5.08 18.77 1.334 21.57C.935 21.87 0 22.681 0 24.023c0 1.342 1.438 1.866 2.444 1.663 8.523-1.719 17.325.954 17.708.164.052-.108.563-1.025-.639-1.469-5.64-2.08-13.788-1.432-15.24-1.22a.069.069 0 0 1-.06-.12c1.911-1.747 21.262-17.58 22.182-19.947C27.078 1.344 25.537 0 24.23 0Z"
        fill="#FF6739"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.818 12.932c-.186-.03-2.051 2.029-4.915 1.998.84-1.623 1.214-2.809 1.214-4.213 0-3.37-2.24-4.181-3.485-4.181-2.79 0-3.227.63-5.049 2.415 0 0-9.787 11.14-4.418 13.775.534.204 1.209.317 2.052.317 3.392 0 6.672-2.934 8.82-6.304 3.729.503 6.59-3.678 5.781-3.807Zm-9.407 3.17s-.874 1.612-1.414 2.353c-.56.767-1.218 1.468-2.011 2.008-.69.47-1.603.87-2.5.604-.712-.212-.389-1.498-.245-2.042.673-2.489 1.946-4.685 3.668-6.603.164-.18.33-.358.498-.533 0 0 .435 2.813 2.153 3.935-.049.098-.149.282-.149.282v-.004Zm2.145-4.064c-.246.617-.504 1.17-.771 1.755-1.646-.92-2.444-5.046-.515-5.545.253-.062.516-.062.768 0 1.526.41.912 2.81.518 3.793v-.003Z"
        fill="#FF6739"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.999 14.723c0-.019-.008-.037-.012-.056a.493.493 0 0 0-.328-.364c-.221-.061-.37.082-.502.22a42.408 42.408 0 0 1-4.232 3.84 17.214 17.214 0 0 1-3.22 2.003h-.008a9.479 9.479 0 0 1-1.222.489c-.645.218-1.387.431-2.074.3-.345-.065-.656-.246-.82-.575-.203-.41-.11-.808-.044-1.24.095-.628.245-1.246.45-1.847.025-.077.376-1.042.374-1.043.38.123.779.173 1.176.146.478-.037.951-.116 1.415-.235a11.78 11.78 0 0 0 3.34-1.513c.961-.619 1.887-1.364 2.566-2.294.435-.597.78-1.305.78-2.055 0-1.249-1.692-4.192-3.983-3.963-3.738.376-8.99 7.208-8.99 12.357 0 1.458.394 2.428.916 3.06.41.476 2.797 2.678 8.64-1.255 1.563-1.084 3.68-2.887 5.695-5.683a.43.43 0 0 0 .083-.292Zm-4.725-4.72s.92-.01-.738 1.568c-1.658 1.577-3.261 2.705-5.095 2.712h-.09c2.457-4.329 5.923-4.28 5.923-4.28Z"
        fill="#FF6739"
      />
    </svg>
  </Link>
)

export default Logo

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Header } from '../styled'
import { Toast, TOAST_TIMEOUT } from '../ToastMessage'
import config from 'config'
import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledButton,
  StyledError,
  StyledValidationLabel,
  StyledValidationContainer,
  StyledInputContainer,
} from './styled'
import { MIN_PASSWORD_LENGTH } from '../hooks/schema'

import CheckIcon from '../../Icons/CheckIcon'
import { useResetPassword } from '../hooks/useResetPassword'

const isPasswordValid = password => password.length >= MIN_PASSWORD_LENGTH

const Form = ({ onSuccess }) => {
  const { register, handleSubmit, watch, errors } = useResetPassword()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const password = watch('password', '')
  const cpassword = watch('cpassword', '')

  useEffect(() => {
    if (error) {
      // a bit of hack
      setTimeout(() => {
        setError(false)
      }, TOAST_TIMEOUT)
    }
  }, [error])

  const isButtonDisabled =
    !isPasswordValid(password) || !isPasswordValid(cpassword) || isLoading

  const onSubmit = async data => {
    setIsLoading(true)
    const params = new URLSearchParams(location.search)

    const token = params.get('t')

    await fetch(`${config.apiURL}/password/reset/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        password: data.password,
      }),
    }).then(res => {
      if (res.status < 400) {
        onSuccess()
      } else {
        setError(true)
      }

      setIsLoading(false)
    })
  }

  const errorMessage = errors.cpassword ? errors.cpassword.message : ''

  return (
    <>
      <Toast show={error} />
      <Header>Enter new password</Header>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLabel>New password</StyledLabel>
        <StyledInputContainer>
          <StyledInput type="password" {...register('password')} />
        </StyledInputContainer>

        <StyledValidationContainer>
          <CheckIcon checked={isPasswordValid(password)} />
          <StyledValidationLabel>
            Password must contain at least {MIN_PASSWORD_LENGTH} characters
          </StyledValidationLabel>
        </StyledValidationContainer>
        <StyledLabel>Confirm New password</StyledLabel>
        <StyledInputContainer>
          <StyledInput type="password" {...register('cpassword')} />
        </StyledInputContainer>
        <StyledError>{errorMessage}</StyledError>
        <StyledButton disabled={isButtonDisabled} type="submit">
          Change password
        </StyledButton>
      </StyledForm>
    </>
  )
}

Form.propTypes = { onSuccess: PropTypes.func.isRequired }
export { Form }
